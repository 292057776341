import { useIndexStore } from '~/stores/index'

type TotalData = ProductResponse | null

interface State {
  totalData: TotalData | null
  newProductList: Novelties[]
  product: Product | {}
  recommendations: Product[]
  products_in_basket: Product[]
  currentCategory: number | null
  noTakeawayCategoryId: number | null
  dayProduct: Product | null
}

export const useProductStore = defineStore({
  id: 'product',
  state: (): State => ({
    totalData: null,
    newProductList: [],
    product: {},
    recommendations: [],
    products_in_basket: [],
    currentCategory: null,
    noTakeawayCategoryId: null,
    dayProduct: null,
  }),
  getters: {
    categoryList(state) {
      return (
        state.totalData?.categories?.map((category) => ({
          id: category.id,
          name: category.name,
        })) || []
      )
    },
    allProducts(state) {
      return (
        state.totalData?.categories.flatMap((category) => category.products) ||
        []
      )
    },
    products_in_basket_length(state) {
      let count = 0
      if (!state.products_in_basket) return 0
      for (let i = 0; i < state.products_in_basket.length; i++) {
        count = count + state?.products_in_basket[i]?.amount
      }
      return count
    },
    categoryProductList(state) {
      if (state.currentCategory !== null) {
        const category = state.totalData?.categories.find(
          (cat) => cat.id === state.currentCategory
        )
        return category ? category.products : []
      }
      return []
    },
    basket_length() {
      const basket = [...JSON.parse(localStorage.getItem('basket'))]
      return basket.reduce(
        (accumulator, currentValue) => accumulator + currentValue.amount,
        0
      )
    },
    isShownProductDay() {
      if (process.client) {
        const store = useIndexStore()
        if (store.current_county) {
          return !sessionStorage.getItem('is_product_of_the_day_shown')
        }
      } else {
        return true
      }
    },
  },
  actions: {
    setProductToBasket(payload: Product) {
      if (process.client && payload) {
        let products = []
        const basket = localStorage.getItem('basket')
        if (basket === null) {
          localStorage.setItem('basket', JSON.stringify([payload]))
          this.products_in_basket = [payload]
        } else {
          products = [...JSON.parse(basket)]
          if (products.find((ell) => ell.id === payload.id)) {
            const product = products.find((ell) => ell.id === payload.id)
            product.amount = product.amount + payload.amount
          } else {
            products.push(payload)
          }
          localStorage.setItem('basket', JSON.stringify(products))
          this.products_in_basket = products
        }
      } else {
        this.products_in_basket = JSON.parse(localStorage?.getItem('basket'))
      }
    },
    updateAmountOfProduct(payload: Product['id'], amount: number) {
      const productIndex = this.products_in_basket.findIndex(
        (item) => item.id === payload
      )
      this.products_in_basket[productIndex].amount = amount
      localStorage.setItem('basket', JSON.stringify(this.products_in_basket))
    },
    deleteProductFromBasket(payload: Product['id']) {
      const productIndex = this.products_in_basket.findIndex(
        (item) => item.id === payload
      )
      this.products_in_basket.splice(productIndex, 1)
      localStorage.setItem('basket', JSON.stringify(this.products_in_basket))
    },

    async fetchProductList() {
      try {
        const city = JSON.parse(localStorage.getItem('city'))
        return await useApi()
          .$get<ProductResponse>('/product/list', {
            params: { city_id: city.id },
          })
          .then((res) => {
            this.noTakeawayCategoryId = res.no_takeaway_category_id || null
            this.totalData = res
            this.newProductList = res?.novelties || []
            useApi()
              .$get(`/product/${res.product_of_the_day}`)
              .then((res) => {
                this.dayProduct = res
              })
          })
      } catch (error) {
        console.error('Ошибка при загрузке данных:', error)
      }
    },
    setIsShownToTrue() {
      sessionStorage.setItem('is_product_of_the_day_shown', 'true')
    },
    selectCategory(categoryId: number) {
      this.currentCategory = categoryId
    },
    getProductByIdList(idList: number[]) {
      return this.allProducts.filter((product) => idList.includes(product.id))
    },
    async getProduct(id: number | string) {
      try {
        this.product = await useApi().$get(`/product/${id}`)
      } catch (e) {
        console.error('Ошибка при загрузке данных:', e)
      }
    },
    async fetchRecommendedProducts() {
      try {
        this.recommendations = await useApi().$get(`/recommendation`)
      } catch (e) {
        console.error('Ошибка при загрузке данных:', e)
      }
    },
  },
})
